<template>
	<div class="auth-success">
		<div class="icon"><i class="el-icon-success"></i></div>
		<div class="tip">授权绑定成功</div>
		<div style="margin-top: 80px;text-align: center;">
			<el-button type="primary" style="width: 300px;" @click="jump">返回商城管理后台</el-button>
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		created() {
		},
		methods: {
			jump(){
				this.$router.replace({
					path: '/'
				})
			}
		},
	}
</script>

<style lang="less" scoped>
	.auth-success{
		.icon{
			font-size: 60px;
			text-align: center;
			color: #409EFF;
			padding-top: 100px;
		}
		.tip{
			font-size: 20px;
			text-align: center;
			margin-top: 10px;
		}
	}
</style>
